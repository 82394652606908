export type ModalProps = Record<string, unknown>;

export type Modal = {
  modalClass: string;
  modalProps?: ModalProps;
};

export type ModalAction = Modal & {
  type: string;
};

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const openModal = (modalClass: string, modalProps?: ModalProps): ModalAction => {
  return {
    type: OPEN_MODAL,
    modalClass,
    modalProps,
  };
};

export const closeModal = (modalClass: string): ModalAction => {
  return {
    type: CLOSE_MODAL,
    modalClass,
  };
};

const removeModal = (modals: Array<Modal>, action: ModalAction) =>
  modals.filter((modal) => modal.modalClass !== action.modalClass);

const addModal = (modals: Array<Modal>, action: ModalAction) => [
  ...removeModal(modals, action),
  {
    modalClass: action.modalClass,
    modalProps: action.modalProps,
  },
];

export type ModalState = Array<Modal>;

export default (state: ModalState = [], action: ModalAction): ModalState => {
  switch (action.type) {
    case OPEN_MODAL:
      return addModal(state, action);
    case CLOSE_MODAL:
      return removeModal(state, action);
    default:
      return state;
  }
};
