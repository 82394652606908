import React, { FunctionComponent, ReactNode } from 'react';

import Panel from './Panel';
import Spinner from './Spinner';

type Props = {
  pending: boolean;
  children: ReactNode;
};

const SubmitPanel: FunctionComponent<Props> = (props: Props) => (
  <Panel>
    {''}
    {props.pending && <Spinner inline />}
    {props.children}
  </Panel>
);

export default SubmitPanel;
