import { Flex } from '@instructure/ui-flex';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  margin?: string;
  alignItems?: string;
  justifyItems?: string;
};

const Panel: React.FC<Props> = (props: Props) => {
  const { children, ...flexProps } = props;

  return (
    <Flex wrap="wrap" {...flexProps}>
      {React.Children.map(children, (child, index) =>
        child || child === '' ? (
          <Flex.Item
            key={String(index)}
            as="div"
            margin={index === 0 ? '0' : '0 0 0 x-small'}
            shouldShrink
            shouldGrow={child === ''}
          >
            {child}
          </Flex.Item>
        ) : null,
      )}
    </Flex>
  );
};

export default Panel;
