import { Pill } from '@instructure/ui-pill';
import { Text as InstText } from '@instructure/ui-text';
import { View } from '@instructure/ui-view';
import I18n from 'i18n-js';
import React from 'react';

import { LogItem } from '../types';

type Props = {
  log: LogItem;
};

const LEVELS = {
  info: {
    color: 'info',
    text: I18n.t('Info'),
  },
  warn: {
    color: 'warning',
    text: I18n.t('Warning'),
  },
  error: {
    color: 'danger',
    text: I18n.t('Error'),
  },
};

const Log: React.FC<Props> = (props: Props) => {
  const { log } = props;
  const { color, text } = LEVELS[log.level as 'info' | 'warn' | 'error'] || {
    color: undefined,
    text: log.level,
  };

  const messages: JSX.Element[] =
    typeof log.message === 'string'
      ? log.message.split('\n').map((msg, key) => <p key={key + msg[0]}>{msg}</p>)
      : [];

  return (
    <View as="div">
      <View as="div">
        <Pill color={color}>{text}</Pill>
      </View>
      <View as="div" margin="x-small xxx-small medium">
        <InstText lineHeight="condensed">
          {messages.length > 1 ? messages : String(log.message)}
        </InstText>
      </View>
    </View>
  );
};

export default Log;
