import { Text as InstText } from '@instructure/ui-text';
import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

export type Queue = {
  text: string;
  defaultStageCount?: number;
  stage?: number;
};

type QueueMap = {
  [name: string]: Queue;
};

export const QUEUES: QueueMap = {
  rawDataFetch: {
    text: I18n.t('Fetch Raw Data'),
    defaultStageCount: 1,
    stage: 1,
  },
  rawDataFetchLarge: {
    text: I18n.t('Fetch Large Raw Data'),
    defaultStageCount: 1,
    stage: 1,
  },
  rosterFetch: {
    text: I18n.t('Fetch Roster'),
    defaultStageCount: 4,
    stage: 1,
  },
  rosterFetchLarge: {
    text: I18n.t('Fetch Large Roster'),
    defaultStageCount: 4,
    stage: 1,
  },
  rosterCleanup: {
    text: I18n.t('Cleanup Roster'),
    defaultStageCount: 4,
    stage: 2,
  },
  rosterTransform: {
    text: I18n.t('Transform Roster'),
    defaultStageCount: 4,
    stage: 2,
  },
  rosterTransformLarge: {
    text: I18n.t('Transform Large Roster'),
    defaultStageCount: 4,
    stage: 2,
  },
  rosterSubmit: {
    text: I18n.t('Submit Roster'),
    defaultStageCount: 4,
    stage: 3,
  },
  rosterImport: {
    text: I18n.t('Check Import'),
    defaultStageCount: 4,
    stage: 4,
  },
  remapSisIdsCanvasReportStart: {
    text: I18n.t('Start Account Report'),
    defaultStageCount: 7,
    stage: 1,
  },
  remapSisIdsRosterFetch: {
    text: I18n.t('Fetch Roster'),
    defaultStageCount: 7,
    stage: 2,
  },
  remapSisIdsRosterFetchLarge: {
    text: I18n.t('Fetch Large Roster'),
    defaultStageCount: 7,
    stage: 2,
  },
  remapSisIdsRosterTransform: {
    text: I18n.t('Transform Roster'),
    defaultStageCount: 7,
    stage: 3,
  },
  remapSisIdsRosterTransformLarge: {
    text: I18n.t('Transform Large Roster'),
    defaultStageCount: 7,
    stage: 3,
  },
  remapSisIdsCanvasReportCheck: {
    text: I18n.t('Check Account Report'),
    defaultStageCount: 7,
    stage: 4,
  },
  remapSisIdsCompare: {
    text: I18n.t('Compare IDs'),
    defaultStageCount: 7,
    stage: 5,
  },
  remapSisIdsSubmit: {
    text: I18n.t('Submit SIS ID changes'),
    defaultStageCount: 7,
    stage: 6,
  },
  remapSisIdsCheck: {
    text: I18n.t('Check Import'),
    defaultStageCount: 7,
    stage: 7,
  },
  rosterCompare: {
    text: I18n.t('Compare Roster'),
    defaultStageCount: 1,
    stage: 1,
  },
  snapshotFetch: {
    text: I18n.t('Fetch Snapshot'),
    defaultStageCount: 9,
    stage: 1,
  },
  snapshotTransform: {
    text: I18n.t('Transform Snapshot'),
    defaultStageCount: 9,
    stage: 2,
  },
  assignmentsGet: {
    text: I18n.t('Get Assignments'),
    defaultStageCount: 9,
    stage: 3,
  },
  assignmentsTransform: {
    text: I18n.t('Transform Assignments'),
    defaultStageCount: 9,
    stage: 4,
  },
  assignmentsPost: {
    text: I18n.t('Post Assignments'),
    defaultStageCount: 9,
    stage: 5,
  },
  submissionsGet: {
    text: I18n.t('Get Submissions'),
    defaultStageCount: 9,
    stage: 6,
  },
  submissionsTransform: {
    text: I18n.t('Transform Submissions'),
    defaultStageCount: 9,
    stage: 7,
  },
  submissionsPost: {
    text: I18n.t('Post Submissions'),
    defaultStageCount: 9,
    stage: 8,
  },
  assignmentsSyncTime: {
    text: I18n.t('Update Sync Time'),
    defaultStageCount: 9,
    stage: 9,
  },
  triggerCumulativeGPB: {
    text: I18n.t('Trigger Grade Sync'),
    defaultStageCount: 2,
    stage: 1,
  },
  monitorCumulativeGPB: {
    text: I18n.t('Monitor Grade Sync'),
    defaultStageCount: 2,
    stage: 2,
  },
  triggerCategoryExchange: {
    text: I18n.t('Trigger Category Sync'),
    defaultStageCount: 2,
    stage: 1,
  },
  monitorCategoryExchange: {
    text: I18n.t('Monitor Category Sync'),
    defaultStageCount: 2,
    stage: 2,
  },
  triggerAssignmentGPB: {
    text: I18n.t('Trigger Grade Sync'),
    defaultStageCount: 2,
    stage: 1,
  },
  monitorAssignmentGPB: {
    text: I18n.t('Monitor Grade Sync'),
    defaultStageCount: 2,
    stage: 2,
  },
  accountReportStart: {
    text: I18n.t('Start Account Report'),
    defaultStageCount: 3,
    stage: 1,
  },
  accountReportCheck: {
    text: I18n.t('Check Account Report'),
    defaultStageCount: 3,
    stage: 2,
  },
  accountReportUpload: {
    text: I18n.t('Upload Account Report'),
    defaultStageCount: 3,
    stage: 3,
  },
  gpbAccountStart: {
    text: I18n.t('Start Account Report'),
    defaultStageCount: 4,
    stage: 1,
  },
  gpbAccountCheckStatus: {
    text: I18n.t('Check Account Report Status'),
    defaultStageCount: 4,
    stage: 2,
  },
  gpbAccountFetch: {
    text: I18n.t('Fetch Account Report'),
    defaultStageCount: 4,
    stage: 3,
  },
  gpbAccountMonitorJobs: {
    text: I18n.t('Schedule and Monitor GPB Jobs'),
    defaultStageCount: 4,
    stage: 4,
  },
  gradeStart: {
    text: I18n.t('Start Report'),
    defaultStageCount: 5,
    stage: 1,
  },
  gradeCheck: {
    text: I18n.t('Check Report'),
    defaultStageCount: 5,
    stage: 2,
  },
  gradeFetch: {
    text: I18n.t('Fetch Report'),
    defaultStageCount: 5,
    stage: 3,
  },
  gradeTransform: {
    text: I18n.t('Transform Report'),
    defaultStageCount: 5,
    stage: 4,
  },
  gradeSubmit: {
    text: I18n.t('Submit Grades'),
    defaultStageCount: 5,
    stage: 5,
  },
  assignmentGPBAccountStart: {
    text: I18n.t('Start Report'),
    defaultStageCount: 5,
    stage: 1,
  },
  assignmentGPBAccountCheck: {
    text: I18n.t('Check Report'),
    defaultStageCount: 5,
    stage: 2,
  },
  assignmentGPBAccountFetch: {
    text: I18n.t('Fetch Report'),
    defaultStageCount: 5,
    stage: 3,
  },
  assignmentGPBAccountTransform: {
    text: I18n.t('Transform Report'),
    defaultStageCount: 5,
    stage: 4,
  },
  assignmentGPBAccountUpload: {
    text: I18n.t('Submit Report'),
    defaultStageCount: 5,
    stage: 5,
  },
  onerosterCsvGpbStart: {
    text: I18n.t('Start Report'),
    defaultStageCount: 5,
    stage: 1,
  },
  onerosterCsvGpbCheck: {
    text: I18n.t('Check Report'),
    defaultStageCount: 5,
    stage: 2,
  },
  onerosterCsvGpbFetch: {
    text: I18n.t('Fetch Report'),
    defaultStageCount: 5,
    stage: 3,
  },
  onerosterCsvGpbTransform: {
    text: I18n.t('Transform Report'),
    defaultStageCount: 5,
    stage: 4,
  },
  onerosterCsvGpbUpload: {
    text: I18n.t('Submit Report'),
    defaultStageCount: 5,
    stage: 5,
  },
  pruneJobs: {
    text: I18n.t('Prune Jobs'),
    defaultStageCount: 1,
    stage: 1,
  },
  sendReport: {
    text: I18n.t('Send Report'),
    defaultStageCount: 1,
    stage: 1,
  },
  backupJobs: {
    text: I18n.t('Backup Jobs'),
    defaultStageCount: 2,
    stage: 1,
  },
  cleanupSnapshots: {
    text: I18n.t('Cleanup ElastiCache Snapshots'),
    defaultStageCount: 2,
    stage: 2,
  },
  sendStats: {
    text: I18n.t('Send Stats'),
    defaultStageCount: 1,
    stage: 1,
  },
  cloudWatch: {
    text: I18n.t('CloudWatch'),
    defaultStageCount: 1,
    stage: 1,
  },
  pauseAgents: {
    text: I18n.t('Pause Agents'),
    defaultStageCount: 1,
    stage: 1,
  },
  abortJob: {
    text: I18n.t('Abort Job'),
  },
  resetIntegrationData: {
    text: I18n.t('Reset Integration Data'),
  },
  previousSnapshotCheck: {
    text: I18n.t('Check Previous Snapshot'),
    defaultStageCount: 3,
    stage: 1,
  },
  fetchSnapshot: {
    text: I18n.t('Get Snapshot from SIS'),
    defaultStageCount: 3,
    stage: 2,
  },
  fetchSnapshotLarge: {
    text: I18n.t('Get Large Snapshot from SIS'),
    defaultStageCount: 3,
    stage: 2,
  },
  verifySnapshot: {
    text: I18n.t('Verify Rostering Snapshot'),
    defaultStageCount: 3,
    stage: 3,
  },
  processRollovers: {
    text: I18n.t('Process Rollovers'),
    defaultStageCount: 1,
    stage: 1,
  },
  createAgentReport: {
    text: I18n.t('Create Agent Report'),
    defaultStageCount: 1,
    stage: 1,
  },
};

export type Props = {
  queue: string;
  stageCount?: number;
};

const JobQueueName: FunctionComponent<Props> = (props: Props) => {
  const found = QUEUES[props.queue];
  const totalStages = props.stageCount || found?.defaultStageCount;
  const order = found?.stage ? `${found.stage}/${totalStages} - ` : '';
  const text = found ? found.text : props.queue;

  return <InstText>{`${order}${text}`}</InstText>;
};

export default JobQueueName;
