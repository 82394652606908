import { Spinner as InstSpinner } from '@instructure/ui-spinner';
import I18n from 'i18n-js';
import React, { FunctionComponent } from 'react';

import SectionContainer from './SectionContainer';

type Props = {
  inline?: boolean;
  size?: string;
  margin?: string;
};

const Spinner: FunctionComponent<Props> = (props: Props) => {
  const title = I18n.t('Loading');
  const { inline = false, size = 'small', margin } = props;

  if (inline) {
    return <InstSpinner renderTitle={title} size="x-small" />;
  }
  return (
    <SectionContainer textAlign="center" margin={margin}>
      <InstSpinner renderTitle={title} size={size} data-testid="spinner" />
    </SectionContainer>
  );
};

export default Spinner;
