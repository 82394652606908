import { Alert } from '@instructure/ui-alerts';
import { Select as InstSelect } from '@instructure/ui-select';
import { Tag } from '@instructure/ui-tag';
import I18n from 'i18n-js';
import groupBy from 'lodash/groupBy';
import React, { Component, Fragment, ReactNode } from 'react';

import { Message } from '../types';

import { renderEmptyOption } from './Select';

export type SelectOption = {
  groupId?: string;
  groupName?: string;
  disabled?: boolean;
  id: string;
  label: string;
};

type OptionsElement = ReactNode;

type Props = {
  renderLabel: ReactNode;
  selectedOptionIds: Array<string>;
  options: Array<SelectOption>;
  grouped?: boolean;
  onChange: (selectedOptions: Array<string>) => void;
  layout: string;
  interaction: string;
  messages?: Array<Message>;
  placeholder?: string;
};

type State = {
  query: string | null;
  inputValue: string;
  isShowingOptions: boolean;
  highlightedOptionId: string | null | undefined;
  announcement: string | null | undefined;
};

class MultipleSelect extends Component<Props, State> {
  state: State = {
    query: null,
    inputValue: '',
    isShowingOptions: false,
    highlightedOptionId: null,
    announcement: null,
  };

  inputRef: HTMLInputElement | null = null;

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    this.setState({
      query: value,
      inputValue: value,
      isShowingOptions: true,
    });
  };

  handleShowOptions = (): void => {
    this.setState({
      isShowingOptions: true,
      announcement: I18n.t('List expanded'),
    });
  };

  handleHideOptions = (): void => {
    this.setState({
      query: null,
      inputValue: '',
      isShowingOptions: false,
      announcement: I18n.t('List collapsed'),
    });
  };

  handleBlur = (): void => {
    this.setState({
      highlightedOptionId: null,
    });
  };

  findOption(optionId: string | null | undefined): SelectOption | undefined {
    return this.props.options.find((option) => option.id === optionId);
  }

  findOptionLabel(optionId: string | null | undefined): string {
    const found = this.findOption(optionId);

    return found ? found.label : '';
  }

  handleHighlightOption = (
    e: React.SyntheticEvent<HTMLInputElement>,
    { id }: SelectOption,
  ): void => {
    e.persist();
    const label = this.findOptionLabel(id);

    this.setState(({ inputValue }) => {
      return {
        // only update input value if using keyboard navigation
        inputValue: label && e.type === 'keydown' ? label : inputValue,
        highlightedOptionId: id,
      };
    });
  };

  handleSelectOption = (e: React.SyntheticEvent<HTMLInputElement>, { id }: SelectOption): void => {
    const { onChange, selectedOptionIds } = this.props;

    const found = this.findOption(id);

    if (!found) {
      return;
    }

    this.setState({
      query: null,
      inputValue: '',
      isShowingOptions: false,
      announcement: I18n.t('%{option} selected', {
        option: found.label,
      }),
    });

    const newSelectedOptions = [...selectedOptionIds, id];

    onChange(newSelectedOptions);
  };

  handleDismissTag = (e: React.SyntheticEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    e.preventDefault();

    const value = e.currentTarget.id;
    const { selectedOptionIds, onChange } = this.props;
    const newSelection = selectedOptionIds.filter((option) => option !== value);

    this.setState(
      {
        highlightedOptionId: null,
      },
      () => {
        if (this.inputRef) {
          this.inputRef.focus();
        }
      },
    );

    onChange(newSelection);
  };

  renderTags(): ReactNode {
    const { selectedOptionIds } = this.props;

    return selectedOptionIds.map((id, index) => (
      <Tag
        dismissible
        key={id}
        id={id}
        title={`${I18n.t('Remove')} ${this.findOptionLabel(id)}`}
        text={this.findOptionLabel(id)}
        margin={index > 0 ? 'xxx-small 0 xxx-small xx-small' : 'xxx-small 0'}
        onClick={this.handleDismissTag}
      />
    ));
  }

  renderGroupedOptions(): ReactNode {
    const { options, selectedOptionIds } = this.props;
    const { query, highlightedOptionId } = this.state;
    const filteredOptions = query
      ? options.filter(({ label }) => label.toLowerCase().startsWith(query.toLowerCase()))
      : options;

    if (filteredOptions.length > 0) {
      const notSelectedValues = filteredOptions.filter(
        ({ id }) => selectedOptionIds.indexOf(id) === -1,
      );
      const grouped = groupBy(notSelectedValues, (element) => element.groupId);

      return Object.keys(grouped).map((groupId) => (
        <InstSelect.Group key={groupId} id={groupId} renderLabel={grouped[groupId][0].groupName}>
          {grouped[groupId].map(({ id, label, disabled }) => (
            <InstSelect.Option
              key={id}
              id={id}
              isDisabled={disabled ? disabled : false}
              isHighlighted={id === highlightedOptionId}
            >
              {label}
            </InstSelect.Option>
          ))}
        </InstSelect.Group>
      ));
    }
    return renderEmptyOption();
  }

  renderSimpleOptions(): OptionsElement {
    const { options, selectedOptionIds } = this.props;
    const { query, highlightedOptionId } = this.state;
    const filteredOptions = query
      ? options.filter(({ label }) => label.toLowerCase().startsWith(query.toLowerCase()))
      : options;

    if (filteredOptions.length > 0) {
      return filteredOptions.map(({ id, label, disabled }) => {
        if (selectedOptionIds.indexOf(id) === -1) {
          return (
            <InstSelect.Option
              key={id}
              id={id}
              isDisabled={disabled ? disabled : false}
              isHighlighted={id === highlightedOptionId}
            >
              {label}
            </InstSelect.Option>
          );
        }

        return null;
      });
    }
    return renderEmptyOption();
  }

  registerInput = (input: HTMLInputElement): void => {
    this.inputRef = input;
  };

  render(): ReactNode {
    const { renderLabel, grouped, selectedOptionIds, ...selectProps } = this.props;
    const { query, inputValue, isShowingOptions, announcement } = this.state;
    const showingInputValue = typeof query === 'string' ? query : inputValue || '';

    return (
      <Fragment>
        <InstSelect
          renderLabel={renderLabel}
          assistiveText={I18n.t('Use arrow keys to navigate options. Multiple selections allowed.')}
          inputValue={showingInputValue}
          isShowingOptions={isShowingOptions}
          inputRef={this.registerInput}
          onBlur={this.handleBlur}
          onInputChange={this.handleInputChange}
          onRequestShowOptions={this.handleShowOptions}
          onRequestHideOptions={this.handleHideOptions}
          onRequestHighlightOption={this.handleHighlightOption}
          onRequestSelectOption={this.handleSelectOption}
          renderBeforeInput={selectedOptionIds.length > 0 ? this.renderTags() : null}
          {...selectProps}
        >
          {grouped ? this.renderGroupedOptions() : this.renderSimpleOptions()}
        </InstSelect>
        <Alert
          liveRegion={() => document.getElementById('alert')}
          liveRegionPoliteness="polite"
          screenReaderOnly
        >
          {announcement}
        </Alert>
      </Fragment>
    );
  }
}

export default MultipleSelect;
