import { CloseButton, Button } from '@instructure/ui-buttons';
import { Heading } from '@instructure/ui-heading';
import { Modal } from '@instructure/ui-modal';
import I18n from 'i18n-js';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import { closeModal } from '../../../uiCommon/redux/modals';
import SubmitPanel from '../SubmitPanel';

type HOCProps = {
  closeModal: (modalClass: string) => void;
};

export type Props = HOCProps & {
  label: string;
  size?: string;
  modalClass: string;
  header?: ReactNode;
  children: ReactNode;
  saveButtonText?: ReactNode;
  saveButtonDataAttribute?: string;
  saveButtonColor?: string;
  onSave?: () => boolean | void | Promise<void>;
  onClose?: () => void;
  pending?: boolean;
  error?: Error | null;
  closeOnSave: boolean;
  defaultFocusElement?: ReactNode | (() => ReactNode);
  disabled: boolean;
  overflow?: string;
  hideCancelButton: boolean;
};

type State = {
  isOpen: boolean;
};

/**
 * A template modal with async state.
 */
export class AsyncModal extends Component<Props, State> {
  static defaultProps = {
    size: 'medium',
    closeOnSave: false,
    saveButtonColor: 'primary',
    defaultFocusElement: null,
    disabled: false,
    overflow: 'scroll',
    hideCancelButton: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  componentDidUpdate(prevProps: Props): void {
    const { pending, error, closeOnSave } = this.props;

    if (prevProps.pending && !pending && !error && closeOnSave) {
      this.closeModal();
    }
  }

  componentWillUnmount(): void {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
    this.removeModal();
  }

  closeModal(): void {
    this.setState({
      isOpen: false,
    });
  }

  removeModal(): void {
    const { modalClass } = this.props;

    this.props.closeModal(modalClass);
  }

  handleClose = (): void => {
    this.closeModal();
  };

  handleSave = (): void => {
    const { onSave, pending, closeOnSave } = this.props;

    let onSaveResult: boolean | void | Promise<void> = undefined;

    if (onSave) {
      onSaveResult = onSave();
    }

    if ((onSaveResult === undefined || onSaveResult) && pending === undefined && closeOnSave) {
      this.closeModal();
    }
  };

  handleExited = (): void => {
    this.removeModal();
  };

  renderHeader(): ReactNode {
    const { header } = this.props;

    return header ? (
      <Modal.Header>
        {typeof header === 'string' ? <Heading>{header}</Heading> : header}
        <CloseButton
          onClick={this.handleClose}
          placement="end"
          offset="medium"
          screenReaderLabel={I18n.t('Close')}
        />
      </Modal.Header>
    ) : null;
  }

  renderBody(): ReactNode {
    const { children } = this.props;

    return <Modal.Body>{children}</Modal.Body>;
  }

  renderFooter(): ReactNode {
    const {
      pending,
      saveButtonColor,
      saveButtonText,
      saveButtonDataAttribute,
      disabled,
      hideCancelButton,
    } = this.props;

    return (
      <Modal.Footer>
        <SubmitPanel pending={!!pending}>
          {!hideCancelButton ? <Button onClick={this.handleClose}>{I18n.t('Close')}</Button> : null}
          {saveButtonText && (
            <Button
              onClick={this.handleSave}
              disabled={pending || disabled}
              color={saveButtonColor}
              data-button={saveButtonDataAttribute ?? ''}
            >
              {saveButtonText}
            </Button>
          )}
        </SubmitPanel>
      </Modal.Footer>
    );
  }

  render(): ReactNode {
    const { size, label, defaultFocusElement, overflow } = this.props;
    const { isOpen } = this.state;

    return (
      <Modal
        size={size}
        label={label}
        open={isOpen}
        onDismiss={this.handleClose}
        onExited={this.handleExited}
        defaultFocusElement={defaultFocusElement}
        shouldCloseOnDocumentClick={false}
        overflow={overflow}
      >
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  closeModal,
};

export default connect(null, mapDispatchToProps)(AsyncModal);
