import { View } from '@instructure/ui-view';
import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  textAlign?: string;
  margin?: string;
  children: ReactNode;
};

const SectionContainer: FunctionComponent<Props> = (props: Props) => {
  const { textAlign = 'start', margin = '0 0 medium', children } = props;

  return (
    <View as="div" margin={margin} textAlign={textAlign}>
      {children}
    </View>
  );
};

export default SectionContainer;
