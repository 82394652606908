import get from 'lodash/get';
import React, { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

import heapEventPropSetterScript from '../scripts/heapEventPropsSetter';
import heapInitScript from '../scripts/heapInit';

export type SessionInfo = {
  fromLti: boolean;
  email: string;
  role: string;
  canvasAccount?: string;
};

export type Props = {
  heapAppId?: string;
  sessionInfo: SessionInfo;
  usageMetricsEnabled?: boolean;
};

export const getHighestCanvasUserRole = (membershipRoles: string) => {
  if (membershipRoles.includes('SysAdmin')) {
    return 'sysadmin';
  }

  if (membershipRoles.includes('Administrator')) {
    return 'administrator';
  }

  if (membershipRoles.includes('Instructor')) {
    return 'instructor';
  }

  return 'user';
};

const HeapProvider = (props: PropsWithChildren<Props>) => {
  const { children } = props;

  const adminHeapAppIds = {
    'sistemic-iad-beta.inscloudgate.net': '3031314893',
    'sistemic-iad-prod.inscloudgate.net': '2426023055',
  };

  const heapAppId = get(adminHeapAppIds, window.location.hostname, props.heapAppId);

  const rolePrefix = props.sessionInfo.fromLti ? 'canvas' : 'sistemic';
  const role = props.sessionInfo.fromLti
    ? getHighestCanvasUserRole(props.sessionInfo.role)
    : props.sessionInfo.role.toLowerCase();

  const heapEventProps: {
    fromLti: boolean;
    role: string;
    isInstructureEmployee: boolean;
    canvasAccount?: string;
  } = {
    fromLti: props.sessionInfo.fromLti,
    role: `${rolePrefix}-${role}`,
    isInstructureEmployee: /@(corp\.)?instructure\.com$/.test(`${props.sessionInfo.email}`),
  };

  if (props.sessionInfo.canvasAccount) {
    heapEventProps.canvasAccount = props.sessionInfo.canvasAccount;
  }

  return (
    <>
      {props.usageMetricsEnabled && heapAppId && (
        <Helmet>
          <script type="text/javascript">{heapInitScript.replace('$APP_ID', heapAppId)}</script>
          <script type="text/javascript">
            {heapEventPropSetterScript.replaceAll('$EVENT_PROPS', JSON.stringify(heapEventProps))}
          </script>
        </Helmet>
      )}
      {children}
    </>
  );
};

export default HeapProvider;
